var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-content-page"},[_c('div',{staticClass:"auth-wrapper px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-card-title',{staticClass:"font-large-1"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_vm._v("Upload Content")]),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('b-button',{staticClass:"upload-content-actions cancel-btn header-cancel-btn float-right",attrs:{"variant":"danger","block":"","disabled":_vm.isLoading,"to":_vm.home_route}},[_vm._v(" Cancel ")])],1)],1)],1),_c('form-wizard',{attrs:{"color":"#5500FD","title":null,"subtitle":null,"shape":"circle","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.uploadContent}},[_c('tab-content',{attrs:{"title":"Content Details","before-change":_vm.contentDetailsForm}},[_c('validation-observer',{ref:"uploadContentForm",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Select Content Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Content Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.content_types,"size":"md"},on:{"change":_vm.updateContentTypeSelection},model:{value:(_vm.content_type),callback:function ($$v) {_vm.content_type=$$v},expression:"content_type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Select Your Role","label-for":"roles"}},[_c('validation-provider',{attrs:{"name":"Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.content_roles,"size":"md"},on:{"change":_vm.updateSelectedRoles},model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Enter Content Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Content Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"content-title","state":errors.length > 0 ? false : null,"name":"content-title"},model:{value:(_vm.content_title),callback:function ($$v) {_vm.content_title=$$v},expression:"content_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Upload Content File","label-for":"file"}},[_c('validation-provider',{attrs:{"name":"Content File","rules":"required","vid":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"mt-1",attrs:{"accept":_vm.accept},model:{value:(_vm.content_file),callback:function ($$v) {_vm.content_file=$$v},expression:"content_file"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.content_file ? _vm.content_file.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.show_script_input)?_c('b-form-group',{attrs:{"label":"Select Content Script","label-for":"script"}},[_c('validation-provider',{attrs:{"name":"Content Script File","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"mt-1",attrs:{"accept":".pdf"},model:{value:(_vm.script),callback:function ($$v) {_vm.script=$$v},expression:"script"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.script ? _vm.script.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1733250632)})],1):_vm._e(),(_vm.show_lyrics_input)?_c('b-form-group',{attrs:{"label":"Select Content Lyrics File","label-for":"lyrics"}},[_c('validation-provider',{attrs:{"name":"Content Lyrics File","rules":"required","accept":".pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"mt-1",model:{value:(_vm.lyrics),callback:function ($$v) {_vm.lyrics=$$v},expression:"lyrics"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.lyrics ? _vm.lyrics.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2217011151)})],1):_vm._e(),(_vm.show_content_duration_input)?_c('b-form-group',{attrs:{"label":"Enter the video length in minutes","label-for":"content-length"}},[_c('validation-provider',{attrs:{"name":"Content Length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"md","state":errors.length > 0 ? false : null,"name":"content-length","type":"number","min":"1"},model:{value:(_vm.content_duration),callback:function ($$v) {_vm.content_duration=$$v},expression:"content_duration"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.show_content_duration_input_message)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.content_duration_input_message))]):_vm._e()]}}],null,false,2795335207)})],1):_vm._e(),(_vm.show_appearances_input)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h5',[_vm._v("Appearances")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"float-right",attrs:{"size":"sm"},on:{"click":_vm.addAppearance}},[_vm._v("Add Appearance")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Enter the timestamps where you appeared in the content, separated by a dot(.)")]),_c('p',[_vm._v("E.g \"2.14\", \"15.22\"")])]),(_vm.show_appearance_threshold_message)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.appearance_threshold_message))]):_vm._e()],1),_vm._l((_vm.appearances_counter),function(counter){return _c('div',{key:counter},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(counter)+_vm._s(_vm.nth(counter))+" Appearance ")])]),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Start Timestamp","label-for":"start-timestamp"}},[_c('validation-provider',{attrs:{"name":"Start Timestamp","rules":"required|appearance_timestamp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-start-timestamp',"state":errors.length > 0 ? false : null,"name":counter + '-start-timestamp',"min":"0"},model:{value:(_vm.appearances_start_timestamp[counter - 1]),callback:function ($$v) {_vm.$set(_vm.appearances_start_timestamp, counter - 1, $$v)},expression:"appearances_start_timestamp[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"End Timestamp","label-for":"end-timestamp"}},[_c('validation-provider',{attrs:{"name":"End Timestamp","rules":"required|appearance_timestamp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-end-timestamp',"state":errors.length > 0 ? false : null,"name":counter + '-end-timestamp',"min":"0"},model:{value:(_vm.appearances_end_timestamp[counter - 1]),callback:function ($$v) {_vm.$set(_vm.appearances_end_timestamp, counter - 1, $$v)},expression:"appearances_end_timestamp[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)})],2):_vm._e()],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter Recording Date","label-for":"content_recording_date"}},[_c('validation-provider',{attrs:{"name":"Content Recording Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'Y-m-d', inline: true }},model:{value:(_vm.content_recording_date),callback:function ($$v) {_vm.content_recording_date=$$v},expression:"content_recording_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Enter Year of Recording Release","label-for":"content_release_year"}},[_c('validation-provider',{attrs:{"name":"Content Release Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"id":"content-release-date","state":errors.length > 0 ? false : null,"name":"content-release-date","config":{ dateFormat: 'Y-m-d', inline: true }},model:{value:(_vm.content_release_year),callback:function ($$v) {_vm.content_release_year=$$v},expression:"content_release_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Select Country of Recording","label-for":"content_recording_country"}},[_c('validation-provider',{attrs:{"name":"Content Country of Recording","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"content-country-of-recording","options":_vm.countries_options,"state":errors.length > 0 ? false : null,"name":"content-country-of-recording"},model:{value:(_vm.content_recording_country),callback:function ($$v) {_vm.content_recording_country=$$v},expression:"content_recording_country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.content_type !== 1)?_c('b-form-group',{attrs:{"label":"Select Genre","label-for":"content_genre"}},[_c('validation-provider',{attrs:{"name":"Content Genre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"content-genre","state":errors.length > 0 ? false : null,"options":_vm.genre_options,"multiple":""},model:{value:(_vm.content_genre),callback:function ($$v) {_vm.content_genre=$$v},expression:"content_genre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3938912542)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Select Recording Language","label-for":"content_recording_language"}},[_c('validation-provider',{attrs:{"name":"Content Recording Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"content-recording-language","state":errors.length > 0 ? false : null,"options":_vm.language_options},model:{value:(_vm.content_recording_language),callback:function ($$v) {_vm.content_recording_language=$$v},expression:"content_recording_language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Content Contributors","before-change":_vm.contributorsForm}},[_c('validation-observer',{ref:"uploadContributorsForm",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h5',[_vm._v("Content Contributors")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"float-right",attrs:{"size":"sm"},on:{"click":_vm.addContributor}},[_vm._v("Add Contributor")])],1)],1),(_vm.contributors_counter <= 0)?_c('h6',[_vm._v("No Contributors Added. Click to add or submit to proceed ")]):_vm._e(),_vm._l((_vm.contributors_counter),function(counter){return _c('b-row',{key:counter,staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"8"}},[_c('h6',[_vm._v("Contributor #"+_vm._s(counter))])]),_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{staticClass:"float-right",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.removeContributor(counter)}}},[_vm._v("Remove #"+_vm._s(counter))])],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Select Roles","label-for":"roles"}},[_c('validation-provider',{attrs:{"name":"Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.contributor_roles,"multiple":""},model:{value:(_vm.contributors_role[counter - 1]),callback:function ($$v) {_vm.$set(_vm.contributors_role, counter - 1, $$v)},expression:"contributors_role[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.show_contributor_sub_role_input[counter - 1])?_c('b-col',{attrs:{"cols":"12"}},[(_vm.show_contributor_sub_role_input[counter - 1])?_c('b-form-group',{attrs:{"label":"Select Specific Roles","label-for":"specific-roles"}},[_c('validation-provider',{attrs:{"name":"Specific Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.content_contributor_sub_roles,"size":"md"},model:{value:(_vm.contributors_sub_role[counter - 1]),callback:function ($$v) {_vm.$set(_vm.contributors_sub_role, counter - 1, $$v)},expression:"contributors_sub_role[counter - 1]"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter ID Number","label-for":counter + '-id-number'}},[_c('validation-provider',{attrs:{"name":"ID Number","rules":"required|validate-id-number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-contributor-id-number',"state":errors.length > 0 ? false : null,"name":counter + '-contributor-id-number',"placeholder":"ID Number","type":"number"},model:{value:(_vm.contributors_id[counter - 1]),callback:function ($$v) {_vm.$set(_vm.contributors_id, counter - 1, $$v)},expression:"contributors_id[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter Surname","label-for":counter + '-surname'}},[_c('validation-provider',{attrs:{"name":"Surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-contributor-surname',"state":errors.length > 0 ? false : null,"name":counter + '-contributor-surname',"placeholder":"Surname","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.contributors_surname[counter - 1]),callback:function ($$v) {_vm.$set(_vm.contributors_surname, counter - 1, $$v)},expression:"contributors_surname[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter Other Names","label-for":counter + '-last-name'}},[_c('validation-provider',{attrs:{"name":"Other Names","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-contributor-other-names',"state":errors.length > 0 ? false : null,"name":counter + '-contributor-other-names',"placeholder":"Other Names","onkeydown":"return /^[a-zA-Z ]*$/.test(event.key)"},model:{value:(_vm.contributors_other_names[counter - 1]),callback:function ($$v) {_vm.$set(_vm.contributors_other_names, counter - 1, $$v)},expression:"contributors_other_names[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter KRA PIN Number","label-for":counter + '-kra-pin-number'}},[_c('validation-provider',{attrs:{"name":"KRA PIN Number","rules":"required|validate-kra-pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-transform":"uppercase"},attrs:{"id":counter + '-contributor-kra-pin-number',"state":errors.length > 0 ? false : null,"name":counter + '-contributor-kra-pin-number',"placeholder":"KRA PIN Number"},model:{value:(_vm.contributors_kra_pin[counter - 1]),callback:function ($$v) {_vm.$set(_vm.contributors_kra_pin, counter - 1, $$v)},expression:"contributors_kra_pin[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter Email","label-for":counter + '-contributor-email'}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-contributor-email',"state":errors.length > 0 ? false : null,"name":counter + '-contributor-email',"placeholder":"Select Email"},model:{value:(_vm.contributors_email[counter - 1]),callback:function ($$v) {_vm.$set(_vm.contributors_email, counter - 1, $$v)},expression:"contributors_email[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)})],2)],1)],1),_c('div',{staticClass:"wizard-progress-status"},[(_vm.upload_content_progress != 0)?_c('b-progress',{staticClass:"progress-bar-success my-1",attrs:{"variant":"primary","value":_vm.upload_content_progress,"max":"100"}}):_vm._e(),(_vm.isLoading)?_c('div',[_c('span',{staticClass:"upload-status mr-1"},[_vm._v("Uploading...")]),_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}})],1):_vm._e()],1),_c('b-button',{staticClass:"upload-content-actions cancel-btn footer-cancel-btn float-right",attrs:{"variant":"danger","block":"","disabled":_vm.isLoading,"to":_vm.home_route}},[_vm._v(" Cancel ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }