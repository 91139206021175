<template>
  <div class="upload-content-page">
    <div class="auth-wrapper px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <!-- <b-link class="brand-logo">
            <vuexy-logo />

            <h2 class="brand-text text-primary ml-1">
              Vuexy
            </h2>
          </b-link> -->

          <b-card-title class="font-large-1">
            <b-row>
              <b-col
                lg="6"
                sm="6"
              >Upload Content</b-col>
              <b-col
                lg="6"
                sm="6"
              >
                <!-- cancel button -->
                <b-button
                  variant="danger"
                  block
                  :disabled="isLoading"
                  class="upload-content-actions cancel-btn header-cancel-btn float-right"
                  :to="home_route"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-card-title>

          <form-wizard
            color="#5500FD"
            :title="null"
            :subtitle="null"
            shape="circle"
            finish-button-text="Submit"
            back-button-text="Previous"
            class=""
            @on-complete="uploadContent"
          >
            <!-- Content Details -->
            <tab-content
              title="Content Details"
              :before-change="contentDetailsForm"
            >
              <validation-observer
                ref="uploadContentForm"
                tag="form"
              >
                <b-row>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <!-- Select Content Type -->
                    <b-form-group
                      label="Select Content Type"
                      label-for="type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Type"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-select
                            v-model="content_type"
                            :options="content_types"
                            size="md"
                            @change="updateContentTypeSelection"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Content Roles -->
                    <b-form-group
                      label="Select Your Role"
                      label-for="roles"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Roles"
                        rules="required"
                      >
                        <!-- <b-form-select
                          v-model="roles"
                          :options="content_roles"
                          size="md"
                          :disabled="member_type_id === 2 || member_type_id === 3"
                          @change="updateSelectedRoles"
                        /> -->
                        <b-form-select
                          v-model="roles"
                          :options="content_roles"
                          size="md"
                          @change="updateSelectedRoles"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Content Title -->
                    <b-form-group
                      label="Enter Content Title"
                      label-for="title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Title"
                        rules="required"
                      >
                        <b-form-input
                          id="content-title"
                          v-model="content_title"
                          :state="errors.length > 0 ? false : null"
                          name="content-title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Content Sub Roles -->
                    <!-- <b-form-group
                      v-if="show_sub_roles_input"
                      label="Select Your Specific Roles"
                      label-for="specific-roles"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Specific Roles"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-select
                            v-model="content_sub_role"
                            :options="content_sub_roles"
                            size="md"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group> -->

                    <!-- Content File -->
                    <b-form-group
                      label="Upload Content File"
                      label-for="file"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content File"
                        rules="required"
                        vid="content"
                      >
                        <b-form-file
                          v-model="content_file"
                          class="mt-1"
                          :accept="accept"
                        />
                        <div class="mt-1">
                          Selected file:
                          <strong>{{
                            content_file ? content_file.name : ""
                          }}</strong>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Content Script File -->
                    <b-form-group
                      v-if="show_script_input"
                      label="Select Content Script"
                      label-for="script"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Script File"
                        rules="required"
                      >
                        <b-form-file
                          v-model="script"
                          class="mt-1"
                          accept=".pdf"
                        />
                        <div class="mt-1">
                          Selected file:
                          <strong>{{ script ? script.name : "" }}</strong>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Content Lyrics File -->
                    <b-form-group
                      v-if="show_lyrics_input"
                      label="Select Content Lyrics File"
                      label-for="lyrics"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Lyrics File"
                        rules="required"
                        accept=".pdf"
                      >
                        <b-form-file
                          v-model="lyrics"
                          class="mt-1"
                        />
                        <div class="mt-1">
                          Selected file:
                          <strong>{{ lyrics ? lyrics.name : "" }}</strong>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-if="show_content_duration_input"
                      label="Enter the video length in minutes"
                      label-for="content-length"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Length"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="content_duration"
                            size="md"
                            :state="errors.length > 0 ? false : null"
                            name="content-length"
                            type="number"
                            min="1"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="show_content_duration_input_message"
                          class="text-danger"
                        >{{ content_duration_input_message }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Performer(Actor) Appearances -->
                    <div v-if="show_appearances_input">
                      <b-row>
                        <b-col cols="6">
                          <h5>Appearances</h5>
                        </b-col>
                        <b-col cols="6">
                          <b-button
                            class="float-right"
                            size="sm"
                            @click="addAppearance"
                          >Add Appearance</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <p>Enter the timestamps where you appeared in the content, separated by a dot(.)</p>
                          <p>E.g "2.14", "15.22"</p>
                        </b-col>
                        <small
                          v-if="show_appearance_threshold_message"
                          class="text-danger"
                        >{{ appearance_threshold_message }}</small>
                      </b-row>
                      <div
                        v-for="counter in appearances_counter"
                        :key="counter"
                      >
                        <b-row>
                          <b-col cols="12">
                            <p style="font-weight: 600">
                              {{ counter }}{{ nth(counter) }} Appearance
                            </p>
                          </b-col>
                          <b-col
                            lg="6"
                            sm="12"
                          >
                            <b-form-group
                              label="Start Timestamp"
                              label-for="start-timestamp"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Start Timestamp"
                                rules="required|appearance_timestamp"
                              >
                                <b-form-input
                                  :id="counter + '-start-timestamp'"
                                  v-model="appearances_start_timestamp[counter - 1]"
                                  :state="errors.length > 0 ? false : null"
                                  :name="counter + '-start-timestamp'"
                                  min="0"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            lg="6"
                            sm="12"
                          >
                            <b-form-group
                              label="End Timestamp"
                              label-for="end-timestamp"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="End Timestamp"
                                rules="required|appearance_timestamp"
                              >
                                <b-form-input
                                  :id="counter + '-end-timestamp'"
                                  v-model="appearances_end_timestamp[counter - 1]"
                                  :state="errors.length > 0 ? false : null"
                                  :name="counter + '-end-timestamp'"
                                  min="0"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <!-- Duration -->
                    <!-- <b-form-group
                      label="Enter Duration of the content (in H.m.s)"
                      label-for="content_duration"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Duration"
                        rules="required"
                      >
                        <the-mask
                          v-model="content_duration"
                          class="form-control"
                          :mask="['##:##:##']"
                          :state="errors.length > 0 ? false : null"
                        />
                        <b-form-input
                          id="content-duration"
                          v-model="content_duration"
                          :state="errors.length > 0 ? false : null"
                          name="content-duration"
                          @blur="updateDuration"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group> -->

                    <!-- Recording Date -->
                    <b-form-group
                      label="Enter Recording Date"
                      label-for="content_recording_date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Recording Date"
                        rules="required"
                      >
                        <b-form-datepicker
                          v-model="content_recording_date"
                          class="form-control"
                          :config="{ dateFormat: 'Y-m-d', inline: true }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Date of Release -->
                    <b-form-group
                      label="Enter Year of Recording Release"
                      label-for="content_release_year"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Release Date"
                        rules="required"
                      >
                        <b-form-datepicker
                          id="content-release-date"
                          v-model="content_release_year"
                          :state="errors.length > 0 ? false : null"
                          name="content-release-date"
                          class="form-control"
                          :config="{ dateFormat: 'Y-m-d', inline: true }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Country of Recording -->
                    <b-form-group
                      label="Select Country of Recording"
                      label-for="content_recording_country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Country of Recording"
                        rules="required"
                      >
                        <v-select
                          id="content-country-of-recording"
                          v-model="content_recording_country"
                          :options="countries_options"
                          :state="errors.length > 0 ? false : null"
                          name="content-country-of-recording"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Sound Recording/Audio Genre -->
                    <b-form-group
                      v-if="content_type !== 1"
                      label="Select Genre"
                      label-for="content_genre"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Genre"
                        rules="required"
                      >
                        <v-select
                          id="content-genre"
                          v-model="content_genre"
                          :state="errors.length > 0 ? false : null"
                          :options="genre_options"
                          multiple
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Recording Language -->
                    <b-form-group
                      label="Select Recording Language"
                      label-for="content_recording_language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Content Recording Language"
                        rules="required"
                      >
                        <v-select
                          id="content-recording-language"
                          v-model="content_recording_language"
                          :state="errors.length > 0 ? false : null"
                          :options="language_options"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
            <!-- Content Details -->
            <tab-content
              title="Content Contributors"
              :before-change="contributorsForm"
            >
              <validation-observer
                ref="uploadContributorsForm"
                tag="form"
              >
                <b-row>
                  <b-col cols="6">
                    <h5>Content Contributors</h5>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      size="sm"
                      class="float-right"
                      @click="addContributor"
                    >Add Contributor</b-button>
                  </b-col>
                </b-row>
                <h6 v-if="contributors_counter <= 0">No Contributors Added. Click to add or submit to proceed
                </h6>
                <b-row
                  v-for="counter in contributors_counter"
                  :key="counter"
                  class="mt-1"
                >
                  <b-col cols="8">
                    <h6>Contributor #{{ counter }}</h6>
                  </b-col>
                  <b-col cols="4">
                    <b-button
                      class="float-right"
                      size="sm"
                      variant="danger"
                      @click="removeContributor(counter)"
                    >Remove #{{ counter }}</b-button>
                  </b-col>
                  <b-col
                    lg="12"
                    sm="12"
                  >
                    <b-form-group
                      label="Select Roles"
                      label-for="roles"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Roles"
                        rules="required"
                      >
                        <v-select
                          v-model="contributors_role[counter - 1]"
                          :options="contributor_roles"
                          multiple
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="show_contributor_sub_role_input[counter - 1]"
                    cols="12"
                  >
                    <!-- Content Contributor Sub Roles -->
                    <b-form-group
                      v-if="show_contributor_sub_role_input[counter - 1]"
                      label="Select Specific Roles"
                      label-for="specific-roles"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Specific Roles"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-select
                            v-model="contributors_sub_role[counter - 1]"
                            :options="content_contributor_sub_roles"
                            size="md"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter ID Number"
                      :label-for="counter + '-id-number'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="ID Number"
                        rules="required|validate-id-number"
                      >
                        <b-form-input
                          :id="counter + '-contributor-id-number'"
                          v-model="contributors_id[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-id-number'"
                          placeholder="ID Number"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Content Contributor Sub Roles -->
                  <!-- <b-col
                    v-show="show_contributor_stage_name_input[counter - 1]"
                    cols="12"
                  >
                    <b-form-group
                      v-show="show_contributor_stage_name_input[counter - 1]"
                      label="Enter Stage Name"
                      label-for="contributor-stage-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Stage Name"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="contributors_stage_name[counter - 1]"
                            :state="errors.length > 0 ? false : null"
                            :name="counter + '-contributor-stage-name'"
                            placeholder="Stage Name"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter Surname"
                      :label-for="counter + '-surname'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Surname"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter + '-contributor-surname'"
                          v-model="contributors_surname[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-surname'"
                          placeholder="Surname"
                          onkeydown="return /[a-z]/i.test(event.key)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter Other Names"
                      :label-for="counter + '-last-name'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Other Names"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter + '-contributor-other-names'"
                          v-model="contributors_other_names[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-other-names'"
                          placeholder="Other Names"
                          onkeydown="return /^[a-zA-Z ]*$/.test(event.key)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter KRA PIN Number"
                      :label-for="counter + '-kra-pin-number'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="KRA PIN Number"
                        rules="required|validate-kra-pin"
                      >
                        <b-form-input
                          :id="counter + '-contributor-kra-pin-number'"
                          v-model="contributors_kra_pin[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-kra-pin-number'"
                          placeholder="KRA PIN Number"
                          style="text-transform: uppercase"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter Email"
                      :label-for="counter + '-contributor-email'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter + '-contributor-email'"
                          v-model="contributors_email[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-email'"
                          placeholder="Select Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter Bank Name"
                      :label-for="counter + '-bank-name'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Bank Name"
                        rules="required"
                      >
                        <b-form-select
                          :id="counter + '-contributor-bank-name'"
                          v-model="contributors_bank_name[counter - 1]"
                          :options="banks_options"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-bank-name'"
                          placeholder="Select Bank"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter Bank Account Branch"
                      :label-for="counter + '-bank-branch'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Bank Account Branch"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter + '-contributor-bank-account-branch'"
                          v-model="contributors_bank_branch[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-bank-branch'"
                          placeholder="Bank Account Branch"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter Bank Account Name"
                      :label-for="counter + '-kra-pin-number'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Bank Account Name"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter + '-contributor-bank-account-name'"
                          v-model="contributors_bank_account_name[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-bank-account-name'"
                          onkeydown="return /^[a-zA-Z ]*$/.test(event.key)"
                          placeholder="Bank Account Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Enter Bank Account Number"
                      :label-for="counter + '-bank-account-number'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Bank Account Number"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter + '-contributor-bank-account-number'"
                          v-model="contributors_bank_account_number[counter - 1]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter + '-contributor-bank-account-number'"
                          placeholder="Bank Account Number"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </validation-observer>
            </tab-content>
          </form-wizard>
          <div class="wizard-progress-status">
            <b-progress
              v-if="upload_content_progress != 0"
              variant="primary"
              class="progress-bar-success my-1"
              :value="upload_content_progress"
              max="100"
            />
            <div v-if="isLoading">
              <span class="upload-status mr-1">Uploading...</span>
              <b-spinner
                class="spinner"
                small
              />
            </div>
          </div>
          <!-- cancel button -->
          <b-button
            variant="danger"
            block
            :disabled="isLoading"
            class="upload-content-actions cancel-btn footer-cancel-btn float-right"
            :to="home_route"
          >
            Cancel
          </b-button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  // BForm,
  BRow,
  BCol,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BFormFile,
  BSpinner,
  BProgress,
  BFormDatepicker,
} from 'bootstrap-vue'
import { required } from '@validations'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { TheMask } from 'vue-the-mask'

extend('appearance_timestamp', {
  validate: (value => {
    let pass = true
    const regex = /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/g
    if (!regex.test(value)) {
      pass = false
    }
    // eslint-disable-next-line camelcase
    const split_time = value.split('.')
    split_time.forEach(time => {
      if (Number.isNaN(Number(time))) {
        pass = false
      }
    })
    return pass
  }),
})

extend('validate-kra-pin', {
  validate: (value => {
    let pass = true
    const onlyLetters = /^[a-zA-Z]+$/
    // eslint-disable-next-line camelcase
    const first_is_letter = value.charAt(0)
    // eslint-disable-next-line camelcase
    const last_is_letter = value.charAt(value.length - 1)
    // Check length
    if (value.length < 11 || value.length > 11) {
      pass = false
    } else {
      // Check if first character is letter
      // eslint-disable-next-line camelcase, no-lonely-if
      if (onlyLetters.test(first_is_letter)) {
        pass = true
        // Check if last character is letter
        if (onlyLetters.test(last_is_letter)) {
          pass = true
        } else {
          pass = false
        }
      } else {
        pass = false
      }
    }
    return pass
  }),
})

extend('validate-id-number', {
  validate: (value => {
    let pass = true
    if (value.length < 6 || value.length > 10) {
      pass = false
    }
    return pass
  }),
})

extend('validate-year-of-release', {
  validate: (value => {
    let pass = true
    if (value.toString().length > 4 || value.toString().length < 4) {
      pass = false
    }
    if (value > new Date().getFullYear()) {
      return false
    }
    return pass
  }),
})

export default {
  components: {
    // BSV
    BButton,
    // BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BSpinner,
    BFormFile,
    BFormSelect,
    BRow,
    BCol,
    BProgress,
    // TheMask,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      content_types: [],
      all_content_types: [],
      content_roles: [],
      all_content_roles: [],
      countries_options: [],
      banks_options: [],
      genre_options: [],
      language_options: [],

      show_sub_roles_input: false,
      show_contract_input: false,
      content_sub_roles: [],
      content_contributor_sub_roles: [],
      show_contributor_sub_role_input: [],
      show_contributor_stage_name_input: [],

      content_type: '',
      content_title: '',
      content_file: null,
      roles: '',
      content_sub_role: '',
      content_duration: '',
      content_recording_date: '',
      content_recording_country: '',
      content_genre: '',
      content_recording_language: '',
      content_release_year: '',
      show_content_duration_input: false,
      show_lyrics_input: false,
      lyrics: null,
      show_script_input: false,
      script: null,

      member_type_id: '',
      content_contract: null,

      contributors_counter: 0,
      contributors: [],
      contributors_surname: [],
      contributors_other_names: [],
      contributors_id: [],
      contributors_role: [],
      contributors_sub_role: [],
      contributors_stage_name: [],
      contributors_kra_pin: [],
      contributors_email: [],
      // contributors_bank_name: [],
      // contributors_bank_branch: [],
      // contributors_bank_account_name: [],
      // contributors_bank_account_number: [],

      contributor_users_suggestions: [],

      contributor_roles: [],

      show_appearances_input: false,
      appearances_counter: 0,
      appearances: [],
      appearances_start_timestamp: [],
      appearances_end_timestamp: [],

      status: '',
      // validation rules
      required,

      accept: '.mp3,.wav',

      home_route: '',

      isLoading: false,

      can_upload_content_file: false,

      total_appearance_time: 0,
      total_start_time_appearances: 0,
      total_end_time_appearances: 0,
      show_appearance_threshold_message: false,
      appearance_threshold_message: 'The cumulative time does not meet our threshold to be a member.',
      show_content_duration_input_message: false,
      content_duration_input_message: 'Enter the length of the content',

      upload_content_progress: 0,
    }
  },
  watch: {
    content_duration(duration) {
      if (duration) {
        this.show_content_duration_input_message = false
      } else {
        this.show_content_duration_input_message = true
      }
      this.total_appearance_time = (this.total_end_time_appearances - this.total_start_time_appearances)
      if (((this.total_appearance_time / duration) * 100) < 50) {
        this.show_appearance_threshold_message = true
      } else {
        this.show_appearance_threshold_message = false
      }
    },
    appearances_start_timestamp(newTime) {
      if (newTime.length > 0) {
        this.total_start_time_appearances = 0
        newTime.forEach(time => {
          if (!(Number.isNaN(time))) {
            this.total_start_time_appearances += Number(time)
          }
        })
        this.total_appearance_time = (this.total_end_time_appearances - this.total_start_time_appearances)
        if (this.content_duration) {
          if (((this.total_appearance_time / this.content_duration) * 100) < 50) {
            this.show_appearance_threshold_message = true
          } else {
            this.show_appearance_threshold_message = false
          }
        } else {
          this.show_content_duration_input_message = true
        }
      }
    },
    appearances_end_timestamp(newTime) {
      if (newTime.length > 0) {
        this.total_end_time_appearances = 0
        newTime.forEach(time => {
          if (!(Number.isNaN(time))) {
            this.total_end_time_appearances += Number(time)
          }
        })
        this.total_appearance_time = (this.total_end_time_appearances - this.total_start_time_appearances)
        if (this.content_duration) {
          if (((this.total_appearance_time / this.content_duration) * 100) < 50) {
            this.show_appearance_threshold_message = true
          } else {
            this.show_appearance_threshold_message = false
          }
        } else {
          this.show_content_duration_input_message = true
        }
      }
    },
    contributors_role(roles) {
      if (roles[this.contributors_counter - 1].includes('Actor/Actress') || roles[this.contributors_counter - 1].includes('Performer')) {
        this.show_contributor_stage_name_input[this.contributors_counter - 1] = true
      } else {
        this.show_contributor_stage_name_input[this.contributors_counter - 1] = false
      }

      if ((roles[this.contributors_counter - 1].includes('Actor/Actress') || roles[this.contributors_counter - 1].includes('Performer'))) {
        this.show_contributor_sub_role_input[this.contributors_counter - 1] = true
        this.content_contributor_sub_roles = []
        this.all_content_roles.forEach(role => {
          // eslint-disable-next-line camelcase
          role.sub_roles.forEach(sub_role => {
            this.content_contributor_sub_roles.push({
              text: sub_role.name,
              value: sub_role.name,
            })
          })
        })
      } else {
        this.show_contributor_sub_role_input[this.contributors_counter - 1] = false
      }
    },
    // contributors_id: {
    //   // eslint-disable-next-line func-names, no-unused-vars
    //   handler(id, _oldId) {
    //     console.log(id.length)
    //     if (id[this.contributors_counter].length > 4) {
    //       this.$http.get(`upload-content/contributors/?id=${id}`)
    //         .then(response => {
    //           console.log(response.data)
    //           this.contributor_users_suggestions = response.data.users
    //         })
    //     }
    //   },
    //   deep: true,
    // },
  },
  async mounted() {
    await this.$http
      .get('/membership/content/types')
      .then(response => {
        this.all_content_types = response.data
        response.data.forEach(type => {
          this.content_types.push({ text: type.name, value: type.id })
          if (this.$router.currentRoute.params.type === type.name) {
            this.content_type = type.id
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    await this.$http
      .get('/banks')
      .then(response => {
        response.data.data.forEach(bank => {
          this.banks_options.push({ text: bank.name, value: bank.name })
        })
      })
      .catch(error => {
        console.log(error)
      })
    await this.$http
      .get('/countries')
      .then(response => {
        response.data.data.forEach(country => {
          this.countries_options.push(country.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    await this.$http
      .get('/genres')
      .then(response => {
        response.data.data.forEach(genre => {
          this.genre_options.push(genre.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    await this.$http
      .get('/languages')
      .then(response => {
        response.data.data.forEach(language => {
          this.language_options.push(language.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    this.member_type_id = JSON.parse(localStorage.getItem('user')).member_type_id
    this.home_route = getHomeRouteForLoggedInUser(permissions[0])
  },
  methods: {
    updateContentTypeSelection(e) {
      this.content_roles = []
      this.contributor_roles = []
      this.all_content_types.forEach(type => {
        if (type.id === e) {
          switch (type.name) {
            case 'Audio-Visual/Dramatic Works':
              this.accept = '.mp4'
              break
            case 'Music Rights':
              this.accept = '.mp3,.wav'
              break
            default:
              this.accept = '.mp3,.wav'
              break
          }
          this.all_content_roles = type.content_roles
          type.content_roles.forEach(role => {
            this.content_roles.push({ text: role.name, value: role.id })
            this.contributor_roles.push(role.name)
          })
          if (this.member_type_id === 2) {
            const index = this.content_roles.find(role => role.text === 'Producer')
            this.roles = index.value
          }
        }
      })
      // Make contents unique in array
      this.contributor_roles = [...new Set(this.contributor_roles)]
    },
    updateSelectedRoles(e) {
      this.content_sub_roles = []
      this.all_content_roles.forEach(role => {
        if (role.sub_roles.length > 0 && role.id === e) {
          // eslint-disable-next-line camelcase
          role.sub_roles.forEach(sub_role => {
            this.content_sub_roles.push({
              text: sub_role.name,
              value: sub_role.id,
            })
          })
        }
      })

      // Show Field to select subroles
      if (this.content_sub_roles.length) {
        this.show_sub_roles_input = true
      } else {
        this.show_sub_roles_input = false
      }

      // Show Appearances Input role is "Actor/Actress"
      const actor = this.all_content_roles.filter(
        type => type.name === 'Actor/Actress',
      )[0]
      if (actor && e === actor.id) {
        this.show_appearances_input = true
        this.show_content_duration_input = true
        this.appearances_counter = 0
        this.appearances_counter += 1
        this.contributors_counter += 1
      } else {
        this.show_appearances_input = false
        this.show_content_duration_input = true
        this.appearances_counter = 0
      }

      const performer = this.all_content_roles.filter(
        type => type.name === 'Performer',
      )[0]
      if (performer && e === performer.id) {
        this.contributors_counter += 1
      } else {
        this.contributors_counter = 0
      }

      // Show lyric input if role is "Producer" for Audio-Visual and Sound Recording
      // Show script input if role is "Producer" for Dramatics works
      // const producer = this.all_content_roles.filter(
      //   type => type.name === 'Producer',
      // )[0]
      // if (this.content_type === 1 || this.content_type === 2) {
      //   // For Sound Recording and Audio Visual
      //   if (producer.id === e) {
      //     this.show_lyrics_input = true
      //   } else {
      //     this.show_lyrics_input = false
      //   }
      // }

      const producer = this.all_content_roles.filter(type => type.name === 'Producer')[0]
      if (producer && producer.id === e) {
        // Show Contract Input For Producer
        this.show_contract_input = true
        // Show lyric input if role is "Producer" for Audio-Visual and Sound Recording
        if (this.content_type === 1 || this.content_type === 2) {
          this.show_lyrics_input = true
        } else {
          // Show script input if role is "Producer" for Dramatics works
          this.show_script_input = false
        }
      } else {
        this.show_contract_input = false
        this.show_lyrics_input = false
        this.show_script_input = false
      }
    },
    addContributor() {
      this.contributors_counter += 1
    },
    addAppearance() {
      this.appearances_counter += 1
    },
    nth(n) {
      // eslint-disable-next-line no-mixed-operators
      return ['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || 'th'
    },
    removeContributor(e) {
      this.contributors_counter -= 1
      this.contributors_surname.splice((e - 1), 1)
      this.contributors_other_names.splice((e - 1), 1)
      this.contributors_stage_name.splice((e - 1), 1)
      this.contributors_id.splice((e - 1), 1)
      this.contributors_role.splice((e - 1), 1)
      this.contributors_sub_role.splice((e - 1), 1)
      this.contributors_kra_pin.splice((e - 1), 1)
      this.contributors_bank_name.splice((e - 1), 1)
      this.contributors_bank_branch.splice((e - 1), 1)
      this.contributors_bank_account_name.splice((e - 1), 1)
      this.contributors_bank_account_number.splice((e - 1), 1)
    },
    contentDetailsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.uploadContentForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    contributorsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.uploadContributorsForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    uploadContent() {
      this.$refs.uploadContributorsForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$el.querySelector('.wizard-footer-right').style.display = 'none'
          this.$el.querySelector('.wizard-footer-left').style.display = 'none'
          // Check if User is Actor in dramatics works and add appearances
          // if (this.content_type == 3 && this.roles.includes('Performer(Actor)')) {
          if (this.content_type === 2 && this.roles === 2) {
            // Check the total performance appearance
            if (this.total_appearance_time < 2) {
              this.$router.replace(this.home_route)
              return
            }

            // Add Performance Appearances to Variable
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < this.appearances_counter; index++) {
              this.appearances[index] = {
                start_timestamp: this.appearances_start_timestamp[index],
                end_timestamp: this.appearances_end_timestamp[index],
              }
            }
          }

          if (this.contributors_counter > 0) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < this.contributors_counter; index++) {
              this.contributors[index] = {
                surname: this.contributors_surname[index],
                other_names: this.contributors_other_names[index],
                id_number: this.contributors_id[index],
                roles: this.contributors_role[index],
                sub_role: this.contributors_sub_role[index],
                stage_name: this.contributors_stage_name[index],
                kra_pin: this.contributors_kra_pin[index],
                email: this.contributors_email[index],
                // bank_name: this.contributors_bank_name[index],
                // bank_branch: this.contributors_bank_branch[index],
                // bank_account_name: this.contributors_bank_account_name[index],
                // bank_account_number: this.contributors_bank_account_number[index],
              }
            }
          }

          const formData = new FormData()
          formData.append('content_type', this.content_type)
          formData.append('content_title', this.content_title)
          formData.append('content', this.content_file)
          formData.append('roles', this.roles)
          formData.append('sub_roles', this.content_sub_role)
          formData.append('appearances', JSON.stringify(this.appearances))
          formData.append('lyrics', this.lyrics)
          formData.append('script', this.script)
          formData.append('contract', this.content_contract)
          formData.append('contributors', JSON.stringify(this.contributors))
          formData.append('recording_date', this.content_recording_date)
          formData.append('recording_country', this.content_recording_country)
          formData.append('genre', this.content_genre)
          formData.append('recording_language', this.content_recording_language)
          formData.append('release_year', this.content_release_year)
          formData.append('duration', this.content_duration)
          const context = this
          this.$http
            .post('/membership/content/upload', formData, {
              onUploadProgress(e) {
                if (e.lengthComputable) {
                  context.upload_content_progress = Math.round((e.loaded / e.total) * 100)
                }
                if (context.upload_content_progress === 100) {
                  context.$el.querySelector('.upload-status').innerText = 'Processing...'
                }
              },
            })
            .then(response => {
              const route = response.data.payment_status ? this.home_route : { name: 'register-payment' }
              this.$router
                .replace(route)
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Content uploaded Successfully!',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                    },
                  })
                })
            })
            .catch(error => {
              console.log(error)
              this.$el.querySelector('.wizard-footer-right').style.display = 'block'
              this.$el.querySelector('.wizard-footer-left').style.display = 'block'
              this.$refs.uploadContentForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please check the content',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style>
.upload-content-actions {
  width: 25%;
}
.submit-btn {
  float: right;
}
.footer-cancel-btn {
  display: none;
}
.wizard-progress-status {
  display: grid;
  grid-template-columns: 88% 10%;
  column-gap: 1rem;
}
.wizard-progress-status .spinner {
  margin-top: 10px;
}
@media screen and (max-width: 820px) {
  .upload-content-actions {
    width: 50%;
    margin-top: 10px;
  }
  .wizard-progress-status {
    display: grid;
    grid-template-columns: 75% 20%;
    column-gap: 1rem;
  }
}
@media screen and (max-width: 540px) {
  .vue-form-wizard .wizard-tab-content {
    padding: 10px 0 0;
  }
  .vue-form-wizard .wizard-card-footer {
    display: flex;
    flex-direction: column;
  }
  .vue-form-wizard .wizard-btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .upload-content-actions {
    width: 90%;
  }
  .submit-btn {
    position: absolute;
    bottom: -90px;
  }
  .cancel-btn {
    position: absolute;
    margin-bottom: 10px;
  }
  .header-cancel-btn {
    display: none;
  }
  .footer-cancel-btn {
    display: block;
  }
}
</style>
